<template>
  <v-chip class="ma-2" color="green" outlined v-if="status" label> Yes </v-chip>
  <v-chip class="ma-2" color="red" outlined v-else label> No </v-chip>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
