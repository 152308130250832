<template>
  <v-row class="customer-detail custom-border-top">
    <v-col md="12" sm="12">
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="table-tbody"
      >
      </v-skeleton-loader>
      <v-simple-table v-else class="simple-table">
        <template v-slot:default>
          <thead>
            <tr>
              <td>
                <v-checkbox
                  v-model="selectedAll"
                  class="hide-margin"
                  color="cyan"
                  dense
                  hide-details
                ></v-checkbox>
              </td>
              <td class="font-size-15 font-weight-600">Salutation</td>
              <td class="font-size-15 font-weight-600">First Name</td>
              <td class="font-size-15 font-weight-600">Last Name</td>
              <td class="font-size-15 font-weight-600">Display Name</td>
              <td class="font-size-15 font-weight-600">Email</td>
              <td class="font-size-15 font-weight-600">Mobile</td>
              <td class="font-size-15 font-weight-600">Default</td>
              <td class="font-size-15 font-weight-600">Status</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(person, index) in rowData" :key="index">
              <td>
                <v-checkbox
                  v-model="selectedRows"
                  class="hide-margin"
                  color="cyan"
                  dense
                  hide-details
                  v-bind:value="person.id"
                ></v-checkbox>
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500 text-capitalize"
              >
                {{ person.title }}.
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                {{ person.first_name }}
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                {{ person.last_name }}
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                {{ person.display_name }}
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500 text-lowercase"
              >
                {{ person.primary_email }}
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                {{ person.primary_phone }}
              </td>
              <td class="font-size-14 font-weight-500">
                <Default :status="person.default"></Default>
              </td>
              <td class="font-size-14 font-weight-500">
                <Status :status="person.activated"></Status>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Default from "@/view/pages/partials/Default.vue";

export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin],
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rowData: [],
      selectedRows: [],
    };
  },
  components: {
    Status,
    Default,
  },
  watch: {
    selectedRows(param) {
      this.$emit("selectedRows", param);
    },
    customer: {
      deep: true,
      immediate: true,
      handler() {
        this.assignData();
      },
    },
  },
  methods: {
    selectCustomer(param) {
      this.$emit("selectCustomer", param);
    },
    assignData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer.persons) === false) {
        _this.rowData = _this.customer.persons;
      }
    },
  },
  mounted() {
    const _this = this;
    _this.assignData();
    _this.$root.$on("clearSelection", () => {
      _this.selectedRows = [];
    });
  },
  beforeDestroy() {
    /*this.$root.$off("clearSelection");*/
  },
  computed: {
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
</script>
